import 'jquery-ui/dist/jquery-ui.js';
import 'jquery-expander/jquery.expander.js';
import {Sparkline} from './sparkline.js';

var indexOf = [].indexOf || function(item) { for (var i = 0, l = this.length; i < l; i++) { if (i in this && this[i] === item) return i; } return -1; };

export var SortableList = {
  filterPanelTimeout: null,
  metadata: {},
  dataset: {},
  parameters: {},

  init: function() {
    $('.init_spinner').show();
    $('.sortable_list .filter_panel').mouseover(SortableList.mouseoverFilterPanel).mouseout(SortableList.mouseoutFilterPanel);
    $('.sortable_list').each(function(e) {
      var id;
      id = $(this).attr('id');
      SortableList.resetMetadata(id);
      SortableList.metadata[id].callbacks = {
        optionsUpdated: $(this).data('options-updated-callback')
      };
      SortableList.resetParameters(id);
      SortableList.resetDataset(id);
      SortableList.getMetadata(id);
      $(window).scroll(function() {
        return $.each(SortableList.metadata, function(k, v) {
          return SortableList.ancestorScroll(k);
        });
      });
      return null;
    });
    $(document).on('click', '.sortable_list_proxy .tools .show a', SortableList.showFilterOptions);
    $(document).on('click', '.sortable_list_proxy .tools .hide a', SortableList.hideFilterOptions);
    $(window).on('unload', SortableList.unload);
    return null;
  },

  unload: function() {
    delete SortableList.metadata;
    delete SortableList.dataset;
    delete SortableList.parameters;
    return null;
  },

  resetMetadata: function(id) {
    if (SortableList.metadata[id] != null) {
      delete SortableList.metadata[id];
    }
    SortableList.metadata[id] = {
      entityLabel: {
        singular: "Entity",
        plural: "Entities"
      },
      perPage: -1,
      columns: [],
      formats: [],
      dataSource: $('#' + id).data('source'),
      preFilter: $('#' + id).data('prefilter'),
      callbacks: {
        optionsUpdated: null
      }
    };
    return null;
  },

  getMetadata: function(id) {
    $.getJSON(SortableList.metadata[id].dataSource, {
      command: 'get_metadata',
      prefilter: SortableList.metadata[id].preFilter
    }, function(data) {
      SortableList.setMetadata(id, data);
      return null;
    }).fail(function(xhr, textStatus, thrownError) {
      return null;
    });
    return null;
  },

  setMetadata: function(id, data) {
    var col, j, len, ref;
    SortableList.metadata[id].entityLabel = data.entity_label;
    SortableList.metadata[id].perPage = data.per_page;
    SortableList.metadata[id].columns = data.columns;
    ref = data.columns;
    for (j = 0, len = ref.length; j < len; j++) {
      col = ref[j];
      if (!col.hidden) {
        SortableList.parameters[id].columns.push(col.label);
      }
    }
    SortableList.metadata[id].formats = data.formats;
    SortableList.parameters[id].sort = data.defaults.sort;
    SortableList.parameters[id].filter.columns = data.defaults.filter_columns;
    SortableList.restoreParameters(id);
    $("#" + id).attr('metadata-loaded', Date.now());
    SortableList.metadataLoaded(id);
    SortableList.getData(id);
    SortableList.prepareOptionsPanel(id);
    SortableList.showOptionsPanel(id);
    return null;
  },

  metadataLoaded: function(id) {
    var col, filter_options, filter_params, j, l, len, len1, len2, len3, len4, m, metadata, n, o, opt, par, parameters, ref, ref1, ref2, ref3, state, tools;
    metadata = SortableList.metadata[id];
    parameters = SortableList.parameters[id];
    $('.sortable_list_proxy').data('id', id);
    ref = metadata.columns;
    for (j = 0, len = ref.length; j < len; j++) {
      col = ref[j];
      if (!(col.filter === null && col.auto_filter === false)) {
        filter_params = [];
        filter_options = [];
        ref1 = parameters.filter.columns;
        for (l = 0, len1 = ref1.length; l < len1; l++) {
          par = ref1[l];
          if (par.column === col.id) {
            filter_params.push(par);
          }
        }
        tools = "<div class='tools'>" + ("<span class='show'><a href='#' data-column='" + col.id + "'>Show</a></span>") + ("<span class='hide' style='display:none;'><a href='#' data-column='" + col.id + "'>Hide</a></span>") + "</div>";
        if (col.auto_filter === true) {
          if (col.type === 'boolean') {
            state = null;
            for (m = 0, len2 = filter_params.length; m < len2; m++) {
              par = filter_params[m];
              if (par.oper === 'eq') {
                state = par.value;
              }
            }
            filter_options.push("<li data-column='" + col.id + "' data-operator='eq' data-value='true' " + (state === true ? "class='checked'" : void 0) + ">Yes</li>");
            filter_options.push("<li data-column='" + col.id + "' data-operator='eq' data-value='false' " + (state === false ? "class='checked'" : void 0) + ">No</li>");
          }
          if (col.type === 'date') {
            null;
          }
        } else {
          if (col.filter && col.filter.options) {
            ref2 = col.filter.options;
            for (n = 0, len3 = ref2.length; n < len3; n++) {
              opt = ref2[n];
              state = false;
              for (o = 0, len4 = filter_params.length; o < len4; o++) {
                par = filter_params[o];
                if (par.oper === 'eq' && par.value === opt.value) {
                  state = true;
                }
              }
              filter_options.push("<li data-column='" + col.id + "' data-operator='eq' data-value='" + opt.value + "' " + ((ref3 = state === true) != null ? ref3 : {
                "class='checked'": ''
              }) + ">" + opt.label + "</li>");
            }
          }
        }
        if (filter_options.length > 0) {
          $('.sortable_list_proxy').append("<div class='column'>" + ("<div class='column_label' id='column_filter_label_" + col.id + "'>" + tools + col.label + "</div>") + ("<ul class='options' id='column_filter_options_" + col.id + "' style='display:none;'>" + (filter_options.join('')) + "</ul>") + "</div>");
        }
      }
    }
    $(".sortable_list_proxy").find('li').click(SortableList.clickFilterPanelItem);
    return null;
  },

  showFilterOptions: function(e) {
    var column;
    e.preventDefault();
    column = $(e.target).data('column');
    $("#column_filter_label_" + column + " .show").hide();
    $("#column_filter_label_" + column + " .hide").show();
    $("#column_filter_options_" + column).show('blind', 250);
    return null;
  },

  hideFilterOptions: function(e) {
    var column;
    e.preventDefault();
    column = $(e.target).data('column');
    $("#column_filter_label_" + column + " .show").show();
    $("#column_filter_label_" + column + " .hide").hide();
    $("#column_filter_options_" + column).hide('blind', 250);
    return null;
  },

  resetParameters: function(id) {
    if (SortableList.parameters[id] != null) {
      delete SortableList.parameters[id];
    }
    SortableList.parameters[id] = {
      page: 1,
      continuousScroll: localStorage.getItem('sortable_list_continuous_scroll') === 'true' || false,
      filter: {
        quick_filter: "",
        columns: []
      },
      sort: null,
      dataLoading: false,
      columns: []
    };
    return null;
  },

  resetDataset: function(id) {
    if (SortableList.dataset[id] != null) {
      delete SortableList.dataset[id];
    }
    SortableList.dataset[id] = {
      total: -1,
      data: [],
      notes: [],
      resultsShown: -1
    };
    SortableList.parameters[id].page = 1;
    $("#" + id).find('.result_list tbody').html('');
    $("#" + id).find('.options').css('top', 0);
    return null;
  },

  setTestData: function(id) {
    var dataset, metadata, parameters;
    dataset = SortableList.dataset[id];
    metadata = SortableList.metadata[id];
    parameters = SortableList.parameters[id];
    dataset.total = 502;
    dataset.data[dataset.total - 1] = void 0;
    metadata.per_page = 10;
    metadata.entityLabel = {
      singular: "Fleem",
      plural: "Fleems"
    };
    metadata.columns.push({
      id: 'word',
      label: 'Word',
      type: 'text',
      sortable: true,
      filter: {
        multiple: false,
        options: [
          {
            label: "Fish",
            value: 'fish'
          }, {
            label: "Swiss Cheese",
            value: 'swiss_cheese'
          }, {
            label: "Asparagus",
            value: 'asparagus'
          }
        ]
      }
    });
    metadata.columns.push({
      id: 'letter',
      label: 'Letter',
      type: 'text',
      sortable: true,
      filter: {
        multiple: true,
        options: [
          {
            label: "A",
            value: 'a'
          }, {
            label: "B",
            value: 'b'
          }, {
            label: "C",
            value: 'c'
          }
        ]
      }
    });
    metadata.columns.push({
      id: 'boolean',
      label: 'Boolean',
      type: 'boolean',
      sortable: false,
      auto_filter: true
    });
    metadata.columns.push({
      id: 'date',
      label: 'Date',
      type: 'date',
      sortable: true,
      auto_filter: true
    });
    metadata.formats.push({
      id: 'csv',
      label: 'CSV'
    });
    metadata.formats.push({
      id: 'pdf',
      label: 'PDF'
    });
    parameters.page = 49;
    dataset.resultsShown = parameters.page * metadata.per_page;
    parameters.filter.columns.push({
      column: 'boolean',
      operator: 'eq',
      value: true
    });
    parameters.filter.columns.push({
      column: 'letter',
      operator: 'eq',
      value: 'b'
    });
    parameters.filter.columns.push({
      column: 'letter',
      operator: 'eq',
      value: 'a'
    });
    parameters.filter.columns.push({
      column: 'date',
      operator: 'lt',
      value: new Date(Date.parse("8/15/2012"))
    });
    parameters.sort = {
      column: 'letter',
      ascending: false
    };
    return null;
  },

  updateFilterPanel: function(id, column) {
    var after, before, col, filterOptions, filterParams, j, l, len, len1, len2, len3, len4, len5, m, metadata, n, o, opt, p, par, parameters, ref, ref1, ref2, sort, sortOptions, state, val, width;
    metadata = SortableList.metadata[id];
    parameters = SortableList.parameters[id];
    filterParams = [];
    if (parameters.filter.columns) {
      ref = parameters.filter.columns;
      for (j = 0, len = ref.length; j < len; j++) {
        par = ref[j];
        if (par.column === column) {
          filterParams.push(par);
        }
      }
    }
    sortOptions = [];
    filterOptions = [];
    ref1 = metadata.columns;
    for (l = 0, len1 = ref1.length; l < len1; l++) {
      col = ref1[l];
      if (col.id === column) {
        if (col.sortable) {
          sort = null;
          if ((parameters.sort != null) && parameters.sort.column === col.id) {
            sort = parameters.sort.ascending;
          }
          sortOptions.push("<li data-column='" + column + "' data-operator='sort' data-value='asc' " + (sort === true ? "class='checked'" : "") + ">Ascending" + (sort === true ? " <i class='far fa-check-circle'></i>" : "") + "</li>");
          sortOptions.push("<li data-column='" + column + "' data-operator='sort' data-value='desc' " + (sort === false ? "class='checked'" : "") + ">Descending" + (sort === false ? " <i class='far fa-check-circle'></i>" : "") + "</li>");
        }
        if (!(col.filter === null && col.auto_filter === false)) {
          if (col.auto_filter === true) {
            if (col.type === 'boolean') {
              state = null;
              for (m = 0, len2 = filterParams.length; m < len2; m++) {
                par = filterParams[m];
                if (par.oper === 'eq') {
                  state = par.value;
                }
              }
              filterOptions.push("<li data-column='" + column + "' data-operator='eq' data-value='true' " + (state === 'true' ? "class='checked'" : "") + ">Yes" + (state === 'true' ? " <i class='far fa-check-circle'></i>" : "") + "</li>");
              filterOptions.push("<li data-column='" + column + "' data-operator='eq' data-value='false' " + (state === 'false' ? "class='checked'" : "") + ">No" + (state === 'false' ? " <i class='far fa-check-circle'></i>" : "") + "</li>");
            }
            if (col.type === 'date') {
              before = null;
              after = null;
              for (n = 0, len3 = filterParams.length; n < len3; n++) {
                par = filterParams[n];
                if (par.oper === 'gt') {
                  after = new Date(par.value);
                }
                if (par.oper === 'lt') {
                  before = new Date(par.value);
                }
              }
              filterOptions.push("<li data-column='" + column + "' data-operator='gt' data-value='" + after + "' " + (after === null ? "" : "class='checked'") + "><span style='display:inline-block;width:40px;'>After</span> <input type='text' class='date' value='" + (after != null ? $.datepicker.formatDate('mm/dd/yy', after) : "") + "' />" + (after === null ? "" : " <i class='far fa-check-circle'></i>") + "</li>");
              filterOptions.push("<li data-column='" + column + "' data-operator='lt' data-value='" + before + "' " + (before === null ? "" : "class='checked'") + "><span style='display:inline-block;width:40px;'>Before</span> <input type='text' class='date' value='" + (before != null ? $.datepicker.formatDate('mm/dd/yy', before) : "") + "' />" + (before === null ? "" : " <i class='far fa-check-circle'></i>") + "</li>");
            }
          } else {
            if (col.filter && col.filter.options) {
              ref2 = col.filter.options;
              for (o = 0, len4 = ref2.length; o < len4; o++) {
                opt = ref2[o];
                val = opt.value;
                if (typeof opt.value === 'string') {
                  val = val.replace("'", '');
                }
                state = false;
                for (p = 0, len5 = filterParams.length; p < len5; p++) {
                  par = filterParams[p];
                  if (par.oper === 'eq' && (par.value.toString() === val || par.value === val)) {
                    state = true;
                  }
                }
                filterOptions.push("<li data-column='" + column + "' data-operator='eq' data-value='" + val + "' " + (state === true ? "class='checked'" : "") + ">" + opt.label + (state === true ? " <i class='far fa-check-circle'></i>" : "") + "</li>");
              }
            }
          }
        }
      }
    }
    if (sortOptions.length > 0) {
      $('.sortable_list .filter_panel').find('.sort').show().next().show().find('ul').html(sortOptions.join(''));
    } else {
      $('.sortable_list .filter_panel').find('.sort').hide().next().hide();
    }
    if (filterOptions.length > 0) {
      $('.sortable_list .filter_panel').find('.filter').show().next().show().find('ul').html(filterOptions.join(''));
    } else {
      $('.sortable_list .filter_panel').find('.filter').hide().next().hide();
    }
    $('.sortable_list .filter_panel').find('li').click(SortableList.clickFilterPanelItem);
    $('.sortable_list .filter_panel').find('li input.date').datepicker({
      showOtherMonths: true,
      selectOtherMonths: true,
      showButtonPanel: true,
      beforeShow: SortableList.showFilterPanelDatePicker,
      onClose: SortableList.closeFilterPanelDatePicker
    }).click(SortableList.clickFilterPanelDateInput);
    width = Number($('.result_list').find("[data-column='" + column + "']").css('width').replace(/[^\d\.]/g, ''));
    $('.sortable_list .filter_panel').css('min-width', width - 2.5);
    return null;
  },

  datePickerIsOpen: false,
  clickFilterPanelDateInput: function(e) {
    e.stopPropagation();
    return null;
  },

  showFilterPanelDatePicker: function(input, inst) {
    SortableList.datePickerIsOpen = true;
    return null;
  },

  closeFilterPanelDatePicker: function(dateText, inst) {
    var date;
    date = new Date(Date.parse(dateText));
    if (isNaN(date)) {
      date = "";
    }
    $(this).parent().data('value', date);
    SortableList.datePickerIsOpen = false;
    $(this).parent().click();
    return null;
  },

  clickFilterPanelItem: function(e) {
    var col, column, i, id, j, l, len, len1, len2, m, match, metadata, multiple, operator, parameters, ref, ref1, ref2, type, value;
    id = $(e.target).closest('.sortable_list').attr('id');
    if (!id) {
      id = $(e.target).closest('.sortable_list_proxy').data('id');
    }
    metadata = SortableList.metadata[id];
    parameters = SortableList.parameters[id];
    column = $(e.target).data('column');
    operator = $(e.target).data('operator');
    value = $(e.target).data('value');
    if (operator === 'sort') {
      if ((parameters.sort != null) && parameters.sort.column === column && parameters.sort.ascending === (value === 'asc')) {
        SortableList.parameters[id].sort = null;
      } else {
        SortableList.parameters[id].sort = {
          column: column,
          ascending: value === 'asc'
        };
      }
    } else {
      multiple = false;
      type = null;
      ref = metadata.columns;
      for (j = 0, len = ref.length; j < len; j++) {
        col = ref[j];
        if (col.id === column) {
          type = col.type;
          if ((col.filter != null) && (col.filter.multiple != null)) {
            multiple = col.filter.multiple;
          }
          break;
        }
      }
      match = null;
      if (parameters.filter.columns) {
        ref1 = parameters.filter.columns;
        for (i = l = 0, len1 = ref1.length; l < len1; i = ++l) {
          col = ref1[i];
          if (col.column === column) {
            if (type === 'date' && col.oper === operator) {
              if (typeof value === 'string') {
                if ($.trim(value) === '') {
                  match = col;
                  SortableList.parameters[id].filter.columns.splice(i, 1);
                  break;
                } else {
                  value = new Date(Date.parse(value));
                }
              }
              if ($.datepicker.formatDate('mm/dd/yy', col.value) === $.datepicker.formatDate('mm/dd/yy', value)) {
                match = col;
                SortableList.parameters[id].filter.columns.splice(i, 1);
              }
              break;
            } else if (type === 'boolean' && col.oper === operator) {
              if (col.value === (value ? "true" : "false")) {
                match = col;
                SortableList.parameters[id].filter.columns.splice(i, 1);
                break;
              }
            } else if (col.oper === operator && col.value === value) {
              match = col;
              SortableList.parameters[id].filter.columns.splice(i, 1);
              break;
            }
          }
        }
      }
      if (match == null) {
        if (parameters.filter.columns) {
          ref2 = parameters.filter.columns;
          for (i = m = 0, len2 = ref2.length; m < len2; i = ++m) {
            col = ref2[i];
            if (col.column === column && !multiple) {
              if (type === 'date' && col.oper === operator) {
                if ($.trim(value) === '') {
                  SortableList.parameters[id].filter.columns.splice(i, 1);
                } else {
                  SortableList.parameters[id].filter.columns[i].value = value;
                }
                match = col;
                break;
              } else if (type === 'boolean' && col.oper === operator) {
                SortableList.parameters[id].filter.columns[i].value = (value ? "true" : "false");
                match = col;
                break;
              } else {
                SortableList.parameters[id].filter.columns[i].oper = operator;
                SortableList.parameters[id].filter.columns[i].value = value;
                match = col;
                break;
              }
            }
          }
          if (match == null) {
            if (type === 'date' && $.trim(value) === '') {

            } else if (type === 'boolean') {
              SortableList.parameters[id].filter.columns.push({
                column: column,
                oper: operator,
                value: (value ? "true" : "false")
              });
            } else {
              SortableList.parameters[id].filter.columns.push({
                column: column,
                oper: operator,
                value: value
              });
            }
          }
        }
      }
    }
    SortableList.hideFilterPanel();
    SortableList.resetDataset(id);
    SortableList.storeParameters(id);
    SortableList.getData(id);
    SortableList.updateOptionsPanel(id);
    return null;
  },

  mouseoverTarget: null,
  mouseoverColumnHead: function(e) {
    var wasOpen;
    wasOpen = SortableList.mouseoverTarget != null;
    SortableList.mouseoverTarget = $(e.target).closest('th');
    clearTimeout(SortableList.filterPanelTimeout);
    if (wasOpen) {
      return SortableList.reactToColumnHeadMouseover();
    } else {
      return SortableList.filterPanelTimeout = setTimeout(SortableList.reactToColumnHeadMouseover, 500);
    }
  },

  reactToColumnHeadMouseover: function() {
    var column, height, id, pos, target;
    $('.sortable_list th.hover').removeClass('hover');
    target = SortableList.mouseoverTarget;
    id = target.closest('.sortable_list').attr('id');
    column = target.data('column');
    target.addClass('hover');
    pos = target.offset();
    height = target.outerHeight();
    $('.sortable_list .filter_panel').css({
      top: (pos.top + height) + 'px',
      left: pos.left + 'px'
    });
    SortableList.updateFilterPanel(id, column);
    SortableList.showFilterPanel();
    if ($('.sortable_list .filter_panel').children(':visible').length === 0) {
      return SortableList.hideFilterPanel();
    }
  },

  mouseoverFilterPanel: function() {
    return SortableList.showFilterPanel();
  },

  showFilterPanel: function() {
    clearTimeout(SortableList.filterPanelTimeout);
    $('.sortable_list .filter_panel').show();
    return null;
  },

  mouseoutColumnHead: function() {
    return SortableList.mouseoutFilterPanel();
  },

  mouseoutFilterPanel: function() {
    clearTimeout(SortableList.filterPanelTimeout);
    if (!SortableList.datePickerIsOpen) {
      SortableList.filterPanelTimeout = setTimeout(function() {
        SortableList.hideFilterPanel();
        SortableList.mouseoverTarget=null;
      }, 500);
    }
    return null;
  },

  hideFilterPanel: function() {
    $('.sortable_list .filter_panel').hide();
    clearTimeout(SortableList.filterPanelTimeout);
    $('.sortable_list th.hover').removeClass('hover');
    SortableList.mouseoverTarget = null;
    return null;
  },

  optionsPanelTop: {},
  prepareOptionsPanel: function(id) {
    $("#" + id).find('.quick_filter').keyup(function() {
      id = $(this).closest('.sortable_list').attr('id');
      SortableList.quickFilterKeyUp(id);
      return false;
    });
    $("#" + id).find('.clear_quick_filter').click(function() {
      id = $(this).closest('.sortable_list').attr('id');
      SortableList.clearCriterion(id, $('.criteria .filter_criteria[data-type="quick"]'));
      return false;
    });
    $("#" + id).find('.scroll_paged').click(function() {
      id = $(this).closest('.sortable_list').attr('id');
      SortableList.showPagedResults(id);
      return false;
    });
    $("#" + id).find('.scroll_continuous').click(function() {
      id = $(this).closest('.sortable_list').attr('id');
      SortableList.showContinuousResults(id);
      return false;
    });
    SortableList.optionsPanelTop[id] = $("#" + id).offset().top;
    return null;
  },

  showOptionsPanel: function(id) {
    $('.init_spinner').hide();
    SortableList.updateOptionsPanel(id);
    $("#" + id).find('.options').show();
    return null;
  },

  updateOptionsPanel: function(id) {
    SortableList.updateOptionsPanelQuantity(id);
    SortableList.updateOptionsPanelFilter(id);
    SortableList.updateOptionsPanelCriteria(id);
    SortableList.updateOptionsPanelDownloadOptions(id);
    SortableList.updateOptionsPanelScrollMode(id);
    SortableList.updateOptionsPanelPageNavigator(id);
    SortableList.updateOptionsPanelColumns(id);
    SortableList.updateOptionsPanelReset(id);
    SortableList.updateTableHeaders(id);
    $("#" + id).attr('options-updated', Date.now());
    return null;
  },

  updateOptionsPanelQuantity: function(id) {
    var current, dataset, label, metadata, parameters, total;
    dataset = SortableList.dataset[id];
    metadata = SortableList.metadata[id];
    parameters = SortableList.parameters[id];
    total = dataset.total;
    current = total > 0 ? dataset.resultsShown : 0;
    label = total === 1 ? metadata.entityLabel.singular : metadata.entityLabel.plural;
    if (current === -1 || total === -1) {
      $("#" + id).find('.quantity').html("<em>Loading</em> " + label);
    } else {
      $("#" + id).find('.quantity').html("<strong>" + current + "</strong> of <strong>" + total + "</strong> " + label);
    }
    return null;
  },

  updateOptionsPanelFilter: function(id) {
    var parameters;
    parameters = SortableList.parameters[id];
    if ((parameters.filter.quick_filter != null) && parameters.filter.quick_filter !== '') {
      $("#" + id).find('.quick_filter').val(parameters.filter.quick_filter);
      return setTimeout(SortableList.showClearQuickFilter, 100);
    }
  },

  updateOptionsPanelCriteria: function(id) {
    var column, criteria, j, label, len, metadata, parameters, quick_filter, ref, summary, title;
    metadata = SortableList.metadata[id];
    parameters = SortableList.parameters[id];
    criteria = [];
    quick_filter = $("#" + id).find('.quick_filter').val();
    if (quick_filter !== '') {
      criteria.push("<li class='filter_criteria' data-type='quick'><i class='far fa-times-circle'></i> Any value includes '" + quick_filter + "'</li>");
    }
    if (parameters.filter.columns) {
      ref = parameters.filter.columns;
      for (j = 0, len = ref.length; j < len; j++) {
        column = ref[j];
        label = SortableList.getColumnTitle(id, column.column);
        summary = SortableList.getColumnFilterSummary(id, column.column, column.oper, column.value);
        criteria.push("<li class='filter_criteria' data-type='filter' data-column='" + column.column + "' data-operator='" + column.oper + "' data-value='" + column.value + "'><i class='far fa-times-circle'></i> " + label + " " + summary + "</li>");
      }
    }
    if (parameters.sort != null) {
      title = SortableList.getColumnTitle(id, parameters.sort.column);
      criteria.push("<li class='filter_criteria' data-type='sort'><i class='far fa-times-circle'></i> Sorted by " + title + " " + (parameters.sort.ascending === true ? " <i class='fas fa-sort-alpha-down'></i>" : " <i class='fas fa-sort-alpha-down-alt'></i>") + "</li>");
    }
    if (criteria.length === 0) {
      $("#" + id).find('.criteria').parent().hide();
    } else {
      $("#" + id).find('.criteria').html(criteria.join('')).parent().show();
      $("#" + id).find('.filter_criteria').attr('title', 'Click to remove').click(function() {
        id = $(this).closest('.sortable_list').attr('id');
        SortableList.clearCriterion(id, this);
        return false;
      });
    }
    return null;
  },

  updateOptionsPanelDownloadOptions: function(id) {
    var formats, item, j, len, links;
    formats = SortableList.metadata[id].formats;
    if (formats.length === 0) {
      $("#" + id).find('.download').parent().hide();
    } else {
      links = [];
      for (j = 0, len = formats.length; j < len; j++) {
        item = formats[j];
        links.push("<a href='#' data-format='" + item.value + "'>" + item.label + "</a>");
      }
      $("#" + id).find('.download').next().html(links.join(' | ')).show();
      $("#" + id).find('.download').next().find('a').click(function(e) {
        e.preventDefault();
        id = $(this).closest('.sortable_list').attr('id');
        SortableList.getFile(id, $(this).data('format'));
        return false;
      });
      $("#" + id).find('.download').parent().show();
    }
    return null;
  },

  updateOptionsPanelScrollMode: function(id) {
    if (SortableList.parameters[id].continuousScroll) {
      $("#" + id).find('.scroll_paged').removeClass('checked').find('i, svg').remove();
      $("#" + id).find('.scroll_continuous').find('i, svg').remove();
      $("#" + id).find('.scroll_continuous').addClass('checked').append(" <i class='far fa-check-circle'></i>");
    } else {
      $("#" + id).find('.scroll_paged').find('i, svg').remove();
      $("#" + id).find('.scroll_paged').addClass('checked').append(" <i class='far fa-check-circle'></i>");
      $("#" + id).find('.scroll_continuous').removeClass('checked').find('i, svg').remove();
    }
    return null;
  },

  updateOptionsPanelPageNavigator: function(id) {
    var arrows, dataset, i, j, lastPage, links, max, metadata, min, parameters, ref, ref1;
    dataset = SortableList.dataset[id];
    metadata = SortableList.metadata[id];
    parameters = SortableList.parameters[id];
    if (parameters.continuousScroll || dataset.total < 1 || metadata.perPage < 1) {
      $("#" + id).find('.page_navigator').parent().hide();
    } else {
      lastPage = Math.ceil(dataset.total / metadata.perPage);
      min = 1;
      max = lastPage;
      if (lastPage > 5) {
        min = Math.max(min, parameters.page - 2);
        max = Math.min(max, parameters.page + 2);
      }
      arrows = [];
      arrows.push("<a " + (parameters.page === 1 ? "class='disabled'" : "href='#'") + " data-page='&lt;'><i class='fas fa-arrow-left'></i></a>");
      arrows.push("<a " + (parameters.page === lastPage ? "class='disabled'" : "href='#'") + " data-page='&gt;'><i class='fas fa-arrow-right'></i></a>");
      links = [];
      links.push("<span class='nav_arrows'>" + (arrows.join(' ')) + "</span>");
      if (min > 1) {
        links.push("<a href='#' data-page='1'>1</a>");
      }
      if (min > 2) {
        links.push("...");
      }
      for (i = j = ref = min, ref1 = max; ref <= ref1 ? j <= ref1 : j >= ref1; i = ref <= ref1 ? ++j : --j) {
        links.push("<a " + (parameters.page === i ? "class='selected'" : "href='#'") + " data-page='" + i + "'>" + i + "</a>");
      }
      if (max < lastPage - 1) {
        links.push("...");
      }
      if (max < lastPage) {
        links.push("<a href='#' data-page='" + lastPage + "'>" + lastPage + "</a>");
      }
      $("#" + id).find('.page_navigator').next().html(links.join(' ')).show();
      $("#" + id).find('.page_navigator').parent().show();
      $("#" + id).find('.page_navigator').next().find('a').click(function() {
        if ($(this).attr('class') !== 'disabled') {
          id = $(this).closest('.sortable_list').attr('id');
          SortableList.navigateToPage(id, $(this).data('page'));
        }
        return false;
      });
    }
    return null;
  },

  updateOptionsPanelColumns: function(id) {
    var column, columns, j, len, metadata, parameters, ref, ref1;
    metadata = SortableList.metadata[id];
    parameters = SortableList.parameters[id];
    columns = [];
    ref = metadata.columns;
    for (j = 0, len = ref.length; j < len; j++) {
      column = ref[j];
      if (column.label !== '') {
        if (ref1 = column.label, indexOf.call(SortableList.parameters[id].columns, ref1) < 0) {
          columns.push("<li class='hide_column'><i class='far fa-square'></i> " + column.label + "</li>");
        } else {
          columns.push("<li class='show_column'><i class='far fa-check-square'></i> " + column.label + "</li>");
        }
      }
    }
    $("#" + id).find('.columns').html(columns.join(' ')).expander('destroy').expander({
      slicePoint: 30,
      sliceOn: '<li>',
      expandText: 'More',
      userCollapseText: 'Less'
    });
    $("#" + id).find('.hide_column').attr('title', 'Click to show').click(function() {
      return SortableList.toggleOptionsPanelColumn(this);
    });
    $("#" + id).find('.show_column').attr('title', 'Click to hide').click(function() {
      return SortableList.toggleOptionsPanelColumn(this);
    });
    return null;
  },

  updateOptionsPanelReset: function(id) {
    $("#" + id).find('.reset').off('click').on('click', SortableList.clearParameters);
    return null;
  },

  toggleOptionsPanelColumn: function(e) {
    var id, idx, label, labelObj, ref;
    id = $(e).closest('.sortable_list').attr('id');
    label = $(e).text();
    if (indexOf.call($(e).attr('class').split(/\s+/), 'show_column') >= 0) {
      labelObj = $(e).closest('.columns').find("li:contains('" + label + "')");
      labelObj.removeClass('show_column').addClass('hide_column').attr('title', 'Click to show');
      labelObj.find("i, svg").replaceWith("<i class='far fa-square'></i>");
      idx = SortableList.parameters[id].columns.indexOf(label.trim());
      if (idx !== -1) {
        SortableList.parameters[id].columns.splice(idx, 1);
      }
    } else {
      labelObj = $(e).closest('.columns').find("li:contains('" + label + "')");
      labelObj.removeClass('hide_column').addClass('show_column').attr('title', 'Click to hide');
      labelObj.find("i, svg").replaceWith("<i class='far fa-check-square'></i>");
      if (ref = label.trim(), indexOf.call(SortableList.parameters[id].columns, ref) < 0) {
        SortableList.parameters[id].columns.push(label.trim());
      }
    }
    SortableList.updateTableHeaders(id);
    $("#" + id).find('.result_list tbody').html('');
    SortableList.navigateToPage(id, 2);
    SortableList.navigateToPage(id, 1);
    return false;
  },

  showPagedResults: function(id) {
    if (SortableList.parameters[id].continuousScroll === true) {
      SortableList.parameters[id].continuousScroll = false;
      SortableList.storeParameters(id);
      localStorage.setItem('sortable_list_continuous_scroll', false);
      SortableList.navigateToPage(id, 1);
      SortableList.updateOptionsPanel(id);
      $("#" + id).find('.options').css('top', 0);
    }
    return null;
  },

  showContinuousResults: function(id) {
    if (SortableList.parameters[id].continuousScroll === false) {
      SortableList.navigateToPage(id, 1);
      SortableList.parameters[id].continuousScroll = true;
      SortableList.storeParameters(id);
      localStorage.setItem('sortable_list_continuous_scroll', true);
      SortableList.updateOptionsPanel(id);
      SortableList.checkScroll(id);
    }
    return null;
  },

  navigateToPage: function(id, page) {
    var baseIndex, dataIsLoaded, dataset, i, j, maxIndex, metadata, newPage, parameters, ref, ref1;
    metadata = SortableList.metadata[id];
    parameters = SortableList.parameters[id];
    dataset = SortableList.dataset[id];
    newPage = parseInt(page);
    if (page === "<") {
      newPage = parameters.page - 1;
    }
    if (page === ">") {
      newPage = parameters.page + 1;
    }
    if (SortableList.parameters[id].page !== newPage) {
      SortableList.parameters[id].page = newPage;
      SortableList.storeParameters(id);
      dataIsLoaded = true;
      baseIndex = (newPage - 1) * metadata.perPage;
      maxIndex = baseIndex + metadata.perPage;
      if (dataset.total > -1 && maxIndex > dataset.total) {
        maxIndex = dataset.total;
      }
      for (i = j = ref = baseIndex, ref1 = maxIndex; ref <= ref1 ? j < ref1 : j > ref1; i = ref <= ref1 ? ++j : --j) {
        if (dataset.data[i] === void 0) {
          dataIsLoaded = false;
          break;
        }
      }
      if (dataIsLoaded) {
        SortableList.showData(id);
      } else {
        SortableList.getData(id);
      }
    }
    return null;
  },

  quickFilterUpdateId: null,
  quickFilterUpdateTimeout: null,
  quickFilterKeyUp: function(id) {
    SortableList.showClearQuickFilter();
    clearTimeout(SortableList.quickFilterUpdateTimeout);
    SortableList.quickFilterUpdateId = id;
    SortableList.quickFilterUpdateTimeout = setTimeout(SortableList.updateQuickFilter, 500);
    return null;
  },

  updateQuickFilter: function() {
    var newFilter;
    var id = SortableList.quickFilterUpdateId;
    newFilter = $("#" + id).find('.quick_filter').val();
    if (SortableList.parameters[id].filter.quick_filter !== newFilter) {
      SortableList.parameters[id].filter.quick_filter = newFilter;
      SortableList.resetDataset(id);
      SortableList.storeParameters(id);
      SortableList.getData(id);
      SortableList.updateOptionsPanel(id);
    }
    return null;
  },

  showClearQuickFilter: function() {
    var cqf, field;
    cqf = $('.clear_quick_filter');
    field = cqf.prev();
    cqf.show().css('left', field.position().left + field.width() - 5).css('top', field.position().top);
    return null;
  },

  clearCriterion: function(id, e) {
    var col, column, i, j, len, operator, ref, ref1, type, value;
    switch ($(e).data('type')) {
      case 'quick':
        SortableList.parameters[id].filter.quick_filter = "";
        $("#" + id).find('.quick_filter').val("");
        $('.clear_quick_filter').hide();
        break;
      case 'sort':
        SortableList.parameters[id].sort = null;
        break;
      default:
        column = $(e).data('column');
        operator = $(e).data('operator');
        value = $(e).data('value');
        type = null;
        ref = SortableList.metadata[id].columns;
        for (j = 0, len = ref.length; j < len; j++) {
          col = ref[j];
          if (col.id === column) {
            type = col.type;
            break;
          }
        }
        ref1 = SortableList.parameters[id].filter.columns;
        for (i in ref1) {
          col = ref1[i];
          if (col.column === column) {
            if (type === 'date' && col.oper === operator) {
              SortableList.parameters[id].filter.columns.splice(i, 1);
              break;
            }
            if (type === 'boolean') {
              SortableList.parameters[id].filter.columns.splice(i, 1);
              break;
            }
            if (col.oper === operator && col.value === value) {
              SortableList.parameters[id].filter.columns.splice(i, 1);
              break;
            }
          }
        }
    }
    SortableList.resetDataset(id);
    SortableList.storeParameters(id);
    SortableList.getData(id);
    SortableList.updateOptionsPanel(id);
    return null;
  },

  getColumnTitle: function(id, column) {
    var col, j, len, metadata, ref, title;
    metadata = SortableList.metadata[id];
    title = column;
    ref = metadata.columns;
    for (j = 0, len = ref.length; j < len; j++) {
      col = ref[j];
      if (col.id === column) {
        title = col.label;
      }
    }
    return title;
  },

  getColumnFilterSummary: function(id, column, operator, value) {
    var col, j, l, label, len, len1, metadata, opt, ref, ref1;
    metadata = SortableList.metadata[id];
    label = value;
    ref = metadata.columns;
    for (j = 0, len = ref.length; j < len; j++) {
      col = ref[j];
      if (col.id === column) {
        if (col.auto_filter === true) {
          switch (col.type) {
            case 'boolean':
              label = value && (value !== 'false') ? 'is True' : 'is False';
              break;
            case 'date':
              switch (operator) {
                case 'lt':
                  label = "is before " + ($.datepicker.formatDate('M d, yy', new Date(value)));
                  break;
                case 'gt':
                  label = "is after " + ($.datepicker.formatDate('M d, yy', new Date(value)));
              }
          }
        } else {
          if (col.filter && col.filter.options) {
            ref1 = col.filter.options;
            for (l = 0, len1 = ref1.length; l < len1; l++) {
              opt = ref1[l];
              if ((opt.value === value) || (opt.value === '' + value) || (typeof opt.value === 'string' && opt.value.replace("'", '') === value)) {
                switch (operator) {
                  case 'eq':
                    label = "is " + opt.label;
                }
              }
            }
          }
        }
      }
    }
    return label;
  },

  updateTableHeaders: function(id) {
    var classes, column, fc, filter_added, headers, icons, j, l, len, len1, metadata, parameters, ref, ref1, ref2;
    metadata = SortableList.metadata[id];
    parameters = SortableList.parameters[id];
    headers = [];
    ref = metadata.columns;
    for (j = 0, len = ref.length; j < len; j++) {
      column = ref[j];
      if (ref1 = column.label, indexOf.call(SortableList.parameters[id].columns, ref1) >= 0) {
        classes = [];
        icons = '';
        filter_added = false;
        if (parameters.filter.columns) {
          ref2 = parameters.filter.columns;
          for (l = 0, len1 = ref2.length; l < len1; l++) {
            fc = ref2[l];
            if (fc.column === column.id && !filter_added) {
              icons += " <i class='fas fa-filter'></i>";
              filter_added = true;
            }
          }
        }
        if ((parameters.sort != null) && parameters.sort.column === column.id) {
          if (parameters.sort.ascending) {
            icons += " <i class='fas fa-sort-alpha-down'></i>";
          } else {
            icons += " <i class='fas fa-sort-alpha-down-alt'></i>";
          }
        }
        headers.push("<th data-column='" + column.id + "' class='" + (classes.join(' ')) + "'>" + column.label + icons + "</th>");
      }
    }
    $("#" + id).find('.result_list thead').html("<tr>" + (headers.join('')) + "</tr>");
    $('.sortable_list th').mouseover(SortableList.mouseoverColumnHead).mouseout(SortableList.mouseoutColumnHead);
    return null;
  },

  getFile: function(id, format) {
    var parameters;
    parameters = SortableList.parameters[id];
    $.fileDownload(SortableList.metadata[id].dataSource, {
      data: {
        command: 'get_file',
        file_format: format,
        prefilter: SortableList.metadata[id].preFilter,
        filter: parameters.filter,
        sort: parameters.sort
      }
    });
    return null;
  },

  getData: function(id) {
    var parameters;
    parameters = SortableList.parameters[id];
    SortableList.parameters[id].dataLoading = true;
    $("#" + id).find('.spinner').show();
    $.getJSON(SortableList.metadata[id].dataSource, {
      command: 'get_data',
      prefilter: SortableList.metadata[id].preFilter,
      page: parameters.page,
      filter: parameters.filter,
      sort: parameters.sort
    }, function(data) {
      $("#" + id).find('.spinner').hide();
      SortableList.parameters[id].dataLoading = false;
      SortableList.setData(id, data);
      return null;
    }).fail(function(xhr, textStatus, thrownError) {
      $("#" + id).find('.spinner').hide();
      SortableList.parameters[id].dataLoading = false;
      if (xhr.status === 401) {
        location.reload();
      }
      return null;
    });
    return null;
  },

  setData: function(id, data) {
    var baseIndex, i, j, ref;
    baseIndex = (data.page - 1) * SortableList.metadata[id].perPage;
    SortableList.dataset[id].total = data.total;
    SortableList.dataset[id].page = data.page;
    if (data.rows.length > 0) {
      for (i = j = 0, ref = data.rows.length - 1; 0 <= ref ? j <= ref : j >= ref; i = 0 <= ref ? ++j : --j) {
        SortableList.dataset[id].data[baseIndex + i] = data.rows[i].cells;
        SortableList.dataset[id].notes[baseIndex + i] = data.rows[i].note;
      }
    }
    SortableList.showData(id);
    return null;
  },

  showData: function(id) {
    var baseIndex, cells, column, columnCounter, dataset, i, j, l, len, page, perPage, ref, ref1, ref2, rowCounter, rows, tbody;
    dataset = SortableList.dataset[id];
    page = SortableList.parameters[id].page;
    perPage = SortableList.metadata[id].perPage;
    baseIndex = (page - 1) * perPage;
    columnCounter = 0;
    rowCounter = 0;
    rows = [];
    for (i = j = 0, ref = perPage; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
      if (dataset.data[baseIndex + i] == null) {
        break;
      }
      cells = [];
      columnCounter = 0;
      ref1 = SortableList.metadata[id].columns;
      for (l = 0, len = ref1.length; l < len; l++) {
        column = ref1[l];
        if (ref2 = column.label, indexOf.call(SortableList.parameters[id].columns, ref2) >= 0) {
          columnCounter++;
          cells.push("<td>" + dataset.data[baseIndex + i][column.id].contents + "</td>");
        }
      }
      rows.push("<tr id='" + id + "_Row_" + (baseIndex + i) + "'>" + (cells.join('')) + "</tr>");
      if ((dataset.notes[baseIndex + i] != null) && !!dataset.notes[baseIndex + i].trim()) {
        cells = [];
        cells.push("<td class='note_label'>Notes:</td>");
        cells.push("<td class='note' colspan='" + (columnCounter - 1) + "'>" + dataset.notes[baseIndex + i] + "</td>");
        rows.push("<tr id='" + id + "_Row_" + (baseIndex + i) + "_Note'>" + (cells.join('')) + "</tr>");
      }
      rowCounter++;
    }
    tbody = $("#" + id).find('.result_list tbody');
    if (SortableList.parameters[id].continuousScroll) {
      if (SortableList.dataset[id].resultsShown < 0) {
        SortableList.dataset[id].resultsShown = 0;
      }
      SortableList.dataset[id].resultsShown += rowCounter;
      tbody.append(rows.join(''));
      SortableList.checkScroll(id);
    } else {
      SortableList.dataset[id].resultsShown = rowCounter;
      tbody.html(rows.join(''));
    }
    SortableList.updateOptionsPanelQuantity(id);
    SortableList.updateOptionsPanelPageNavigator(id);
    $('.hideable').expander({
      slicePoint: 150,
      sliceOn: '<i>',
      expandText: 'More',
      userCollapseText: 'Less'
    });
    Sparkline.refreshSparklines();
    return null;
  },

  checkScroll: function(id) {
    var page, perPage, tbody, total;
    tbody = $("#" + id).find('.result_list tbody');
    if ($(document).height() - $(window).innerHeight() - $(window).scrollTop() < $(window).innerHeight()) {
      page = SortableList.parameters[id].page;
      perPage = SortableList.metadata[id].perPage;
      total = SortableList.dataset[id].total;
      if (page * perPage < total) {
        SortableList.navigateToPage(id, page + 1);
      }
    }
    return null;
  },

  ancestorScroll: function(id) {
    var minOffset, obj, offset;
    minOffset = 55;
    obj = $("#" + id).find('.options');
    offset = 0 - obj.parent().offset().top + minOffset;
    obj.css('top', offset > 0 ? offset : 0);
    if (SortableList.parameters[id].continuousScroll === true) {
      if (SortableList.parameters[id].dataLoading === false) {
        SortableList.checkScroll(id);
      }
    }
    return null;
  },

  storeParameters: function(id) {
    localStorage.setItem('sortable_list_parameters_' + id, JSON.stringify(SortableList.parameters[id]));
    return null;
  },

  restoreParameters: function(id) {
    var params;
    params = JSON.parse(localStorage.getItem('sortable_list_parameters_' + id));
    if (params !== null) {
      SortableList.parameters[id] = params;
    }
    return null;
  },

  clearParameters: function(e) {
    var id;
    id = $(e.target).closest('.sortable_list').attr('id');
    localStorage.setItem('sortable_list_parameters_' + id, null);
    SortableList.resetParameters(id);
    SortableList.resetDataset(id);
    SortableList.showOptionsPanel(id);
    SortableList.getMetadata(id);
    return false;
  },

  // Based on http://stackoverflow.com/questions/359788/how-to-execute-a-javascript-function-when-i-have-its-name-as-a-string
  // as of 7/10/2014
  executeFunctionByName: function(functionName, context) {
    var args, func, i, j, namespaces, ref;
    args = Array.prototype.slice.call(arguments, 2);
    namespaces = functionName.split(".");
    func = namespaces.pop();
    for (i = j = 0, ref = namespaces.length; 0 <= ref ? j < ref : j > ref; i = 0 <= ref ? ++j : --j) {
      context = context[namespaces[i]];
    }
    return context[func].apply(context, args);
  }
};

$(function() {
  SortableList.init();
});

